import { Parser } from 'html-to-react'

const baseUrl = process.env.GATSBY_IMAGE_BASE_URL.replace(/\/$/g, '');

const HtmlParser = ({ htmlString }) => {
  const processedHtmlString = htmlString.replace(/(['",])(\/uploads\/([^'",]+))/gi, `$1${baseUrl}$2`)
  return (new Parser()).parse(processedHtmlString)
}

export default HtmlParser
