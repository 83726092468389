import React from 'react';
import { Link } from 'gatsby';

import MainLogo from '../../assets/images/logo.svg'
import FooterLogo from '../../assets/images/footer-logo.svg'

const Logo = ({ header }) => {

  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <Link className="column logo" to={rootPath}>
      {header ? <MainLogo /> : <FooterLogo />}
    </Link>
  )
};

export default Logo;