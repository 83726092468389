exports.components = {
  "component---src-components-pages-about-us-about-us-js": () => import("./../../../src/components/pages/aboutUs/aboutUs.js" /* webpackChunkName: "component---src-components-pages-about-us-about-us-js" */),
  "component---src-components-pages-articles-articles-js": () => import("./../../../src/components/pages/articles/articles.js" /* webpackChunkName: "component---src-components-pages-articles-articles-js" */),
  "component---src-components-pages-contact-contact-js": () => import("./../../../src/components/pages/contact/contact.js" /* webpackChunkName: "component---src-components-pages-contact-contact-js" */),
  "component---src-components-pages-faq-faq-js": () => import("./../../../src/components/pages/faq/faq.js" /* webpackChunkName: "component---src-components-pages-faq-faq-js" */),
  "component---src-components-pages-homepage-homepage-js": () => import("./../../../src/components/pages/homepage/homepage.js" /* webpackChunkName: "component---src-components-pages-homepage-homepage-js" */),
  "component---src-components-pages-info-info-js": () => import("./../../../src/components/pages/info/info.js" /* webpackChunkName: "component---src-components-pages-info-info-js" */),
  "component---src-components-pages-services-service-js": () => import("./../../../src/components/pages/services/service.js" /* webpackChunkName: "component---src-components-pages-services-service-js" */),
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404_de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-query-1-js": () => import("./../../../src/pages/query1.js" /* webpackChunkName: "component---src-pages-query-1-js" */)
}

