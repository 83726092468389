const _ = require('lodash')
const languageConfig = require('../components/language/config')

const processResponseWithLocalizations = (inputData) => {
  inputData = JSON.parse(JSON.stringify(inputData))

  const languages = Object.keys(languageConfig.languages)
  const isSupportedLanguage = (language) => languages.includes(language)

  if (Array.isArray(inputData?.data)) {
    const data = {}

    if (inputData?.data.length === 0) {
      return data
    }

    inputData.data.forEach((item) => {
      const attributes = item?.attributes

      if (!isSupportedLanguage(attributes.locale)) {
        return
      }

      if (!(attributes.locale in data)) {
        data[attributes.locale] = []
      }

      data[attributes.locale].push(_.omit(attributes, ['localizations']))

      const localizations = attributes?.localizations?.data

      if (Array.isArray(localizations)) {
        localizations.forEach((localization) => {
          const language = localization?.attributes?.locale

          if (!isSupportedLanguage(language)) {
            return
          }

          if (language) {
            if (!(language in data)) {
              data[language] = []
            }

            data[language].push(_.cloneDeep(localization.attributes))
          }
        })
      }
    })

    return data
  }

  const attributes = inputData?.data?.attributes

  if (!attributes) {
    return {}
  }

  if (!isSupportedLanguage(attributes.locale)) {
    return
  }

  const data = {
    [attributes.locale]: _.omit(attributes, ['localizations'])
  }

  const localizations = attributes?.localizations?.data

  if (Array.isArray(localizations)) {
    localizations.forEach((localization) => {
      const language = localization?.attributes?.locale

      if (!isSupportedLanguage(language)) {
        return
      }

      if (language) {
        data[language] = _.cloneDeep(localization.attributes)
      }
    })
  }

  return data
}

module.exports = {
  processResponseWithLocalizations,
}
