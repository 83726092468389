import React from 'react';
import TrustPilot from 'assets/images/trustpilot.svg'

const TrustPilotLink = () => {
  const url = process.env.GATSBY_TRUSTPILOT_URL;
  return (
    <a href={url} target="_blank">
      <TrustPilot />
    </a>
  )
};

export default TrustPilotLink;
