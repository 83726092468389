import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import * as cls from './topMenu.module.scss'

import Logo from 'assets/images/logo.svg'

const Index = ({ menuItems, bottomMenuItems }) => {
  useEffect(() => {
    window.addEventListener('scroll', stickyHeader)
    stickyHeader()

    return () => {
      window.removeEventListener('scroll', stickyHeader)
    }
  })

  const rootPath = `${__PATH_PREFIX__}/`
  const stickyHeader = () => {
    const header = document.getElementById('mainHeader')

    if (window.scrollY > 500) {
      header.classList.add(`${cls.is_sticky}`)
      document.documentElement.style.paddingTop = '4rem'
    } else {
      header.classList.remove(`${cls.is_sticky}`)
      document.documentElement.style.paddingTop = '0'
    }
  }

  const menu = () => {
    if (!menuItems) {
      return null
    }

    return menuItems.map((item, i) => {
      let additionalItemClasses = ''
      if (item.visibility === 'Mobile_only') {
        additionalItemClasses += `${cls.nav__itemMobileOnly}`
      } else if (item.visibility === 'Desktop_only') {
        additionalItemClasses += `${cls.nav__itemDesktopOnly}`
      }

      if (item.items) {
        return (
          <div className={`navbar-item ${cls.has__dropdown} ${cls.nav__item} ${additionalItemClasses}`} key={i}>
            <span className={`navbar-link ${cls.nav__link}`}>
              {item.label}
            </span>

            <div className={`navbar-dropdown ${cls.nav__dropdown}`}>
              {item.items.map((item, i) => {
                return (
                  <Link className={`${cls.nav__dropdown_item}`} key={i} to={item.url}>
                    {item.label}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      } else {
        return <Link className={`navbar-item ${cls.nav__item} ${additionalItemClasses}`} key={i} to={item.url}>
          {item.label}
        </Link>
      }
    })
  }

  const [openMenu, setOpenMenu] = useState(false)

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)

    document.body.classList.toggle('overflow')
  }

  const openMenuClass = openMenu ? `${cls.open}` : null
  const activeBurger = openMenu ? `${cls.active}` : null


  const emptyHandler = () => { return false }

  return (
    <header id="mainHeader" className={cls.header}>
      <div className={`container ${cls.container}`}>
        <div className={`columns ${cls.header__body}`}>
          <Link className={`column logo ${cls.logo}`} to={rootPath}>
            <Logo />
          </Link>
          <span
            className={`navbar-burger column ${cls.nav__burger} ${activeBurger}`}
            data-target="topNavbarMenu"
            onClick={handleOpenMenu}
            role="button"
            tabIndex={0}
            onKeyDown={emptyHandler}
          >
            <span></span>
            <span></span>
          </span>
          <nav id="topNavbarMenu" className={`column ${cls.nav} ${openMenuClass}`}>
            <div className={`navbar columns ${cls.nav__items}`}>
              {menu(menuItems)}
              <div className={cls.nav__footer}>
                {bottomMenuItems && bottomMenuItems.map((item) =>
                  <Link className={cls.nav__footer_link} to={item.url} key={item.label}>{item.label}</Link>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div >
    </header >
  )
}

export default Index
