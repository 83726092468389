import { graphql, useStaticQuery } from 'gatsby'
import graphqlHelpers from 'helpers/graphql'

export const useSiteData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        api {
          site {
            data {
              attributes {
                title
                locale
                seo {
                  ...seoFields
                }
                blockWhyOurService {
                  title
                  items {
                    title
                    text
                    image {
                      ...imageFields
                    }
                  }
                }
                blockCTA {
                  title
                  items {
                    title
                    url
                  }
                }
                companyInfo {
                  name
                  value
                }

                localizations {
                  data {
                    attributes {
                      title
                      locale
                      seo {
                        ...seoFields
                      }
                      blockWhyOurService {
                        title
                        items {
                          title
                          text
                          image {
                            ...imageFields
                          }
                        }
                      }
                      blockCTA {
                        title
                        items {
                          title
                          url
                        }
                      }
                      companyInfo {
                        name
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return graphqlHelpers.processResponseWithLocalizations(data.api.site)
}
