import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import '@fontsource/poppins'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/lato'
import '@fontsource/lato/700.css'

import Layout from './src/components/layout'

const wrapPageElement = ({ element, props }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
    <Layout {...props}>{element}</Layout>
  </GoogleReCaptchaProvider>
)

export default wrapPageElement
