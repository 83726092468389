import React, { useEffect } from 'react'

import { useMenusData } from '~/graphql/hooks/menus'
import { useSiteData } from '~/graphql/hooks/site'
import SiteContext from '~/helpers/siteContext'
import TopMenu from './TopMenu'
import { Footer } from 'components/layout/Footer'

import { LanguageContext } from 'components/language/languageContext'
import { Helmet } from 'react-helmet'

const Layout = ({ children, pageContext: { language } }) => {
  const data = useMenusData()
  const sitesData = useSiteData()
  const menus = data[language] ?? {}

  useEffect(() => {
    console.log('run cookiebotCustomization time: ', Date.now()) // TODO test
    window.addEventListener('CookiebotOnDialogDisplay', function () {
      console.log('fire CookiebotOnDialogDisplay time: ', Date.now()) // TODO test
      document.getElementById("CybotCookiebotDialogBodyLevelButtonsTable").classList.add("d-none")
      document.getElementById("CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection").classList.add("d-none")
      document.getElementById("CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll").setAttribute("style", "display: flex;")
      document.getElementById("CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection").setAttribute("style", "display: flex;")

      const container = document.getElementById("CybotCookiebotDialogBodyLevelButtonAcceptWrapper")
      const el = document.createElement('button')
      el.classList.add('cookie-settings-btn')
      const text = document.createTextNode('Einstellungen')
      const textBtnAcceptAll = 'Alle erlauben'
      el.appendChild(text)
      el.id = 'settings-btn'
      el.addEventListener('click', function () {
        document.getElementById('CybotCookiebotDialogBodyLevelButtonsTable').classList.toggle('d-none')
        document.getElementById('CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection').classList.toggle('d-none')
        document.getElementById('settings-btn').classList.add('d-none')
        document.getElementById('CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll').innerText = textBtnAcceptAll
      })
      container.appendChild(el)
    }, false)
  }, [])

  const cookieBotApiKey = process.env.GATSBY_COOKIEBOT_API_KEY

  return (
    <LanguageContext.Provider value={{ language }}>
      <SiteContext.Provider value={sitesData[language] ?? {}}>
        <TopMenu menuItems={menus.mainTop} bottomMenuItems={menus.mainBottom} />
<Helmet>
  <script
    id="Cookiebot"
    data-culture="de"
    src="https://consent.cookiebot.com/uc.js"
    data-cbid={cookieBotApiKey}
    data-blockingmode="auto"
    type="text/javascript">
  </script>
</Helmet>
        <main className="main">
          {children}
        </main>

        <Footer topMenuItems={menus.footerTop} bottomMenuItems={menus.mainBottom}  />
      </SiteContext.Provider>
    </LanguageContext.Provider>
  )
}

export default Layout
