module.exports = {
  defaultLanguage: 'de',
  prefixDefault: false, // All routes will be prefixed, including the defaultLang
  languages: {
    en: {
      path: 'en',
      locale: 'en-US',
      dateFormat: 'DD/MM/YYYY',
    },
    de: {
      path: 'de',
      locale: 'de-DE',
      dateFormat: 'DD.MM.YYYY',
    },
  },
}
