import React from 'react'
import { Link } from 'gatsby'

import HtmlParser from 'components/HtmlParser'
import Logo from '../../Logo'
import TrustPilotLink from 'components/TrustPilotLink'

import * as cls from './footer.module.scss'

// Icons
import GoogleReviews from 'assets/images/google-reviews.svg'

export function Footer({ topMenuItems, bottomMenuItems }) {
  return (
    <div className={cls.footer}>
      <div className="container">
        <div className={cls.footer_inner}>
          <div className={cls.footer_icons}>
            <Logo />
            <div className={cls.footer_icons_links}>
              <TrustPilotLink />
              <Link to="/#"><GoogleReviews /> </Link>
            </div>
          </div>
          {topMenuItems && topMenuItems.map((item) =>
            <div key={item.label}>
              <h4>{item.label}</h4>
              {
                item.items &&
                <ul>
                  {item.items.map((subItem) => <li key={subItem.label}><Link to={subItem.url}>{subItem.label}</Link></li>)}
                </ul>
              }
              {item.content && <HtmlParser htmlString={item.content} />}
            </div>
          )}
        </div>
        <div className={cls.footer_copyright}>
          <p>{new Date().getFullYear()} &copy; Alle Rechte vorbehalten.</p>
          <ul>
            {bottomMenuItems && bottomMenuItems.map((item) => <li key={item.label}><Link to={item.url}>{item.label}</Link></li>)}
          </ul>
        </div>
      </div>
    </div>
  )
}
