import { useStaticQuery, graphql } from 'gatsby'
import graphqlHelpers from 'helpers/graphql'

export const useMenusData = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        menu {
          data {
            attributes {
              mainTop {
                ...on Api_ComponentNavigationMenuItem {
                  label
                  visibilityMenuItem: visibility
                  url
                }
                ...on Api_ComponentNavigationDropdown {
                  label
                  visibilityDropdown: visibility
                  items {
                    label
                    url
                  }
                }
              }
              mainBottom {
                ...on Api_ComponentNavigationMenuItem {
                  label
                  visibilityMenuItem: visibility
                  url
                }
              }
              footerTop {
                ...on Api_ComponentNavigationMenuItemText {
                  label
                  content
                }
                ...on Api_ComponentNavigationDropdown {
                  label
                  visibilityDropdown: visibility
                  items {
                    label
                    url
                  }
                }
              }
              footerBottom {
                ...on Api_ComponentNavigationMenuItem {
                  label
                  visibilityMenuItem: visibility
                  url
                }
              }
              locale

              localizations {
                data {
                  attributes {
                    mainTop {
                      ...on Api_ComponentNavigationMenuItem {
                        label
                        visibilityMenuItem: visibility
                        url
                      }
                      ...on Api_ComponentNavigationDropdown {
                        label
                        visibilityDropdown: visibility
                        items {
                          label
                          url
                        }
                      }
                    }
                    mainBottom {
                      ...on Api_ComponentNavigationMenuItem {
                        label
                        visibilityMenuItem: visibility
                        url
                      }
                    }
                    footerTop {
                      ...on Api_ComponentNavigationMenuItemText {
                        label
                        content
                      }
                      ...on Api_ComponentNavigationDropdown {
                        label
                        visibilityDropdown: visibility
                        items {
                          label
                          url
                        }
                      }
                    }
                    footerBottom {
                      ...on Api_ComponentNavigationMenuItem {
                        label
                        visibilityMenuItem: visibility
                        url
                      }
                    }
                    locale
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menus = graphqlHelpers.processResponseWithLocalizations(data.api.menu)

  deepIterate(menus)

  return menus
}

function deepIterate(o) {
  Object.keys(o).forEach(function (k) {
    if (o[k] !== null && typeof o[k] === 'object') {
      deepIterate(o[k])
      return
    }

    // Fix attribute visibility
    if ('visibilityMenuItem' in o) {
      o.visibility = o.visibilityMenuItem
      delete o.visibilityMenuItem
    } else if ('visibilityDropdown' in o) {
      o.visibility = o.visibilityDropdown
      delete o.visibilityDropdown
    }
  })
}
